import React from 'react';
import { withRouter } from 'next/router';
import Head from 'next/head';
import Image from 'next/image';
import classnames from 'classnames';

import { eventOn } from '../utils/global';

import styles from '../sass/pages/Ascension.module.scss';

const COLLECTIONS = [
  {
    id: 1,
    name: 'Genesis',
    text: 'Sold Out',
    link: 'http://opensea.io/collection/neonpantheon',
  },
  {
    id: 2,
    name: 'Ascension',
    text: 'LAUNCHING SOON',
  },
];

const FRIENDS1 = [
  {
    id: 1,
    name: 'Project Godjira',
    image: 'project_godjira@2x.png',
    width: 154,
    height: 56,
  },
  {
    id: 2,
    name: 'Llama Verse',
    image: 'llama_verse@2x.png',
    width: 140,
    height: 110,
  },
  {
    id: 3,
    name: 'Z',
    image: 'z@2x.png',
    width: 70,
    height: 79,
  },
  {
    id: 4,
    name: 'Chain Runners',
    image: 'chain_runners@2x.png',
    width: 191,
    height: 77,
  },
];

const FRIENDS2 = [
  {
    id: 5,
    name: 'GM.',
    image: 'gm@2x.png',
    width: 100,
    height: 45,
  },
  {
    id: 6,
    name: 'World of Alpha',
    image: 'world_of_alpha@2x.png',
    width: 79,
    height: 97,
  },
  {
    id: 7,
    name: 'Planktoons',
    image: 'planktoons@2x.png',
    width: 186,
    height: 38,
  },
  {
    id: 8,
    name: 'Mindblowon Universe',
    image: 'mindblowon_universe@2x.png',
    width: 206,
    height: 53,
  },
];

class AscensionPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ isMobile: window.innerWidth < 768 });

      eventOn('resize', (e) => {
        this.setState({ isMobile: window.innerWidth < 768 });
      }, window);
    }
  }

  renderCollectionCard = (item) => {
    return (
      <div className={styles.collectionCard} key={item.id}>
        <div className={styles.imageWrap}>
          <Image
            src={`/images/ascension/${item.name}@2x.jpg`}
            alt={`${item.name} collection image`}
            width='440'
            height='408'
          />
          <div className={styles.text}>
            <div className={styles.name}>{item.name}</div>
            Collection
          </div>
        </div>
        <div className={classnames(styles.controls, { [styles.spaceBetween]: item.link })}>
          <span>{item.text}</span>
          {item.link
            ? <span className={styles.openseaLink} onClick={() => window.open(item.link, '_blank')}>Buy on OpenSea</span>
            : null 
          }
        </div>
      </div>
    );
  }

  renderFriendsCard = (item) => {
    return (
      <div key={item.id} className={styles.imageWrap}>
        <Image
          src={`/images/ascension/friends/${item.image}`}
          alt={item.name}
          width={item.width}
          height={item.height}
        />
      </div>
    );
  }

  openWhitepaper = () => {
    window.open('/files/Neon-Pantheon-White-Paper-v1.pdf', '_blank');
  }

  render = () => {

    return (
      <>
        <Head>
          <title>Neon Pantheon NFT | Mint your deity</title>
          <meta name='description' content='Neon Pantheon is an NFT Collectible project about celebrating our differences as humans, through adopting gender-inclusive Deities that are an amalgamation of cultures, mythologies and fiction.' />
          <link rel='icon' href='/images/meta/favicon.png' />
          <meta name='application-name' content='NeonPantheon' />
          <meta name='apple-mobile-web-app-capable' content='yes' />
          <meta name='apple-mobile-web-app-status-bar-style' content='default' />
          <meta name='apple-mobile-web-app-title' content='NeonPantheon' />
          <meta name='format-detection' content='telephone=no' />
          <meta name='mobile-web-app-capable' content='yes' />
          <meta name='theme-color' content='#2a2c44' />
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />

          <link rel='apple-touch-icon' sizes='180x180' href='/images/meta/apple-touch-icon.png' />
          <link rel='manifest' href='/manifest.json' />

          <meta property='og:site_name' content='NeonPantheon' />
          <meta property='og:type' content='website' />
          <meta name='twitter:site_name' content='NeonPantheon' />
          <meta name='twitter:site' content='@NeonPantheon' />

          <meta property='og:url' content='https://neonpantheon.com' />
          <meta property='og:title' content='Neon Pantheon NFT | Mint your deity' />
          <meta property='og:image' content='https://neonpantheon.com/images/meta/card.jpg' />
          <meta property='og:description' content='Neon Pantheon is an NFT Collectible project about celebrating our differences as humans, through adopting gender-inclusive Deities that are an amalgamation of cultures, mythologies and fiction. 👇 See our site for roadmap ideas.' />
          <meta name='twitter:title' content='Neon Pantheon NFT | Mint your deity' />
          <meta name='twitter:description' content='Neon Pantheon is an NFT Collectible project about celebrating our differences as humans, through adopting gender-inclusive Deities that are an amalgamation of cultures, mythologies and fiction. 👇 See our site for roadmap ideas.' />
          <meta name='twitter:image' content='https://neonpantheon.com/images/meta/card.jpg' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='image' content='https://neonpantheon.com/images/meta/card.jpg' />
        </Head>

        <div className={styles.ascensionPage}>
          <div className={styles.heroBg}>
            <Image
              src='/images/ascension/hero@2x.jpg'
              alt='Neon Pantheon Hero background image'
              layout='fill'
              objectFit={this.state.isMobile ? 'cover' : 'contain'}
            />
          </div>
          <div className={styles.hero}>
            <div className='container small text-center'>
              <h2>Launching soon</h2>
              <Image
                src='/images/ascension/ascension@2x.png'
                alt='Ascension text image'
                width='545'
                height='64'
                objectFit={this.state.isMobile ? 'cover' : 'contain'}
              />
              <p>Neon Pantheon is an <span>experiential Augmented Reality NFT project</span> where deities exist in a realm only accessible by Neonites, worshippers of the deities.<br />To understand our project, we recommend you to read our Whitepaper, it’s an easy 5-minute read.</p>
              <button onClick={this.openWhitepaper}>Read our Whitepaper</button>
            </div>
          </div>
          <div className={styles.collectionsSection}>
            <div className='container small'>
              <div className={styles.flex}>
                { COLLECTIONS.map(item => this.renderCollectionCard(item)) }
              </div>
            </div>
          </div>
          <div className={styles.friendsSection}>
            <div className='container small'>
              <h2>Our Friends</h2>
              <div className={styles.flex}>
                { FRIENDS1.map(item => this.renderFriendsCard(item)) }
              </div>
              <div className={styles.flex}>
                { FRIENDS2.map(item => this.renderFriendsCard(item)) }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AscensionPage);